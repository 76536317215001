var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.convenzione.nome)+" - Reportistica Convenzione "),_c('Confirm',{attrs:{"message":"Aggiungi allegato","width":"400px","enabled":_vm.enabled},on:{"confirm":_vm.uploadAllegato},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.$auth('amministratore'))?_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"color":"success"}},'v-btn',attrs,false),on),[_vm._v(" Carica file ")]):_vm._e()]}},{key:"default",fn:function(){return [_c('v-select',{attrs:{"items":_vm.tipologia,"label":"Tipologia"},model:{value:(_vm.args.tipologia),callback:function ($$v) {_vm.$set(_vm.args, "tipologia", $$v)},expression:"args.tipologia"}}),_c('v-file-input',{attrs:{"label":"Allegato","required":""},model:{value:(_vm.args.allegato),callback:function ($$v) {_vm.$set(_vm.args, "allegato", $$v)},expression:"args.allegato"}}),_c('DatePicker',{attrs:{"label":"Data caricamento"},model:{value:(_vm.args.data_upload),callback:function ($$v) {_vm.$set(_vm.args, "data_upload", $$v)},expression:"args.data_upload"}})]},proxy:true}])})],1),_c('v-data-table',{attrs:{"headers":[
            {value:'nomefile', text: 'Nome file'},
            {value:'s_tipologia', text: 'Tipologia'},
            {value:'data_upload', text: 'Data Caricamento'},
            {value:'s_stato', text: 'Stato'},
            {value:'data_approvazione_rifiuto', text: ''},
            {value:'actions', align: 'right'}],"items":_vm.allegati},scopedSlots:_vm._u([{key:"item.data_ora",fn:function(ref){
            var item = ref.item;
return [_c('LocalDate',{attrs:{"time":"","date":item.data_ora}})]}},{key:"item.data_upload",fn:function(ref){
            var item = ref.item;
return [_c('LocalDate',{attrs:{"date":item.data_upload}})]}},{key:"item.data_approvazione_rifiuto",fn:function(ref){
            var item = ref.item;
return [(item.stato ==  'approvato')?_c('b',[_vm._v("Data Approvazione: ")]):_vm._e(),(item.stato ==  'rifiutato')?_c('b',[_vm._v("Data Rifiuto: ")]):_vm._e(),_c('LocalDate',{attrs:{"time":"","date":item.data_approvazione_rifiuto}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.download(item.id)}}},[_vm._v(" Download ")]),_c('Confirm',{attrs:{"message":"Approvazione?","width":"450px","item":item},on:{"confirm":_vm.approvazione},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.$auth(['amministratore', 'consip']) && !item.stato)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"success"}},'v-btn',attrs,false),on),[_vm._v(" Approva ")]):_vm._e()]}}],null,true)}),_c('Confirm',{attrs:{"message":"Rifiuto?","width":"450px","item":item},on:{"confirm":_vm.rifiuto},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.$auth(['amministratore', 'consip']) && !item.stato)?_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"error"}},'v-btn',attrs,false),on),[_vm._v(" Rifiuta ")]):_vm._e()]}}],null,true)}),_c('Confirm',{attrs:{"message":"Confermi la cancellazione del file?","width":"450px","item":item},on:{"confirm":_vm.cancella},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.$auth('amministratore'))?_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"error"}},'v-btn',attrs,false),on),[_vm._v(" Cancella ")]):_vm._e()]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }